<template>
  <Frame>
    <h1>Despierta el barista que llevas dentro</h1>
    <p>
      <b>¿A quien no le gusta un buen café?</b> Especialmente por las mañanas,
      una taza de café recien preparada con nuestra máquina de café puede darnos
      la energía necesaria para empezar con buen pie el día.
    </p>
    <p>
      Es habitual empezar en el mundo del café tomando café instantáneo, pero
      mas pronto que tarde querrás
      <b>probar algo con más sabor y más elegante</b>.
    </p>
    <p>
      Si has llegado a aquí es que estas buscando comprar una buena cafetera
      automática o cafetera express; o bien reemplazar tu cafetera italiana de
      toda la vida. <b>Busca y compara</b> los diferentes modelos y anímate a
      comprar el modelo que más te guste.
    </p>
    <h1>Análisis de las mejores cafeteras de 2021</h1>
    <Cluster />
    <h1>Catálogo de cafetras al mejor precio:</h1>
    <AffiliateGrid />
    <h1>¿Cuál es tu cafetera ideal?</h1>
    <p>
      Hoy en día existen un sinfin de modelos por lo que no es de extrañar
      pregurntarse <b>¿Qué cafetera debo comprar</b>? No te preocupes, en
      <b>micafetera.net</b> las hemos clasificado todas para que encuentres la
      mejor cafetera que se ajuste a tus necesidades.
    </p>
    <div class="machine-types">
      <article class="machine-type">
        <img
          src="../assets/home_type_express.webp"
          alt="Cafeteras express"
          class="machine-type-image"
        />
        <h2 class="machine-type-title">Cafeteras express</h2>
      </article>
      <article class="machine-type">
        <img
          src="../assets/home_type_automatic.webp"
          alt="Cafeteras automáticas"
          class="machine-type-image"
        />
        <h2 class="machine-type-title">Cafeteras automáticas</h2>
      </article>
      <article class="machine-type">
        <img
          src="../assets/home_type_capsules.jpg"
          alt="Cafeteras de cápsulas"
          class="machine-type-image"
        />
        <h2 class="machine-type-title">Cafeteras de cápsulas</h2>
      </article>
      <article class="machine-type">
        <img
          src="../assets/home_type_dropping.webp"
          alt="Cafeteras de goteo"
          class="machine-type-image"
        />
        <h2 class="machine-type-title">Cafeteras de goteo</h2>
      </article>
      <article class="machine-type">
        <img
          src="../assets/home_type_italian.webp"
          alt="Cafeteras italianas"
          class="machine-type-image"
        />
        <h2 class="machine-type-title">Cafeteras italianas</h2>
      </article>
      <article class="machine-type">
        <img
          src="../assets/home_type_french.webp"
          alt="Cafeteras de prensa francesa"
          class="machine-type-image"
        />
        <h2 class="machine-type-title">Cafeteras de prensa francesa</h2>
      </article>
    </div>
    <h1>Marcas de cafeteras</h1>
    <p>
      Te mostramos las características y las mejores ofertas de cafeteras de las
      principales marcas del sector.
    </p>
    <div class="machine-types">
      <article class="machine-type">
        <img
          src="../assets/brand_dolce_gusto.webp"
          alt="Cafeteras Dolce Gusto"
          class="machine-brand-image"
        />
        <h2 class="machine-type-title">Cafeteras Dolce Gusto</h2>
      </article>
      <article class="machine-type">
        <img
          src="../assets/brand_nespresso.png"
          alt="Cafeteras Nespresso"
          class="machine-brand-image"
        />
        <h2 class="machine-type-title">Cafeteras Nespresso</h2>
      </article>
      <article class="machine-type">
        <img
          src="../assets/brand_oster.webp"
          alt="Cafeteras Oster"
          class="machine-brand-image"
        />
        <h2 class="machine-type-title">Cafeteras Oster</h2>
      </article>
      <article class="machine-type">
        <img
          src="../assets/brand_delonghi.webp"
          alt="Cafeteras Delonghi"
          class="machine-brand-image"
        />
        <h2 class="machine-type-title">Cafeteras Delonghi</h2>
      </article>
      <article class="machine-type">
        <img
          src="../assets/brand_krups.webp"
          alt="Cafeteras Krups"
          class="machine-brand-image"
        />
        <h2 class="machine-type-title">Cafeteras Krups</h2>
      </article>
      <article class="machine-type">
        <img
          src="../assets/brand_cecotec.png"
          alt="Cafeteras Cecotec"
          class="machine-brand-image"
        />
        <h2 class="machine-type-title">Cafeteras Cecotec</h2>
      </article>
      <article class="machine-type">
        <img
          src="../assets/brand_philips.webp"
          alt="Cafeteras Philips"
          class="machine-brand-image"
        />
        <h2 class="machine-type-title">Cafeteras Philips</h2>
      </article>
      <article class="machine-type">
        <img
          src="../assets/brand_melitta.png"
          alt="Cafeteras Melitta"
          class="machine-brand-image"
        />
        <h2 class="machine-type-title">Cafeteras Melitta</h2>
      </article>
      <article class="machine-type">
        <img
          src="../assets/brand_ikohs.png"
          alt="Cafeteras Ikohs"
          class="machine-brand-image"
        />
        <h2 class="machine-type-title">Cafeteras Ikohs</h2>
      </article>
      <article class="machine-type">
        <img
          src="../assets/brand_siemens.webp"
          alt="Cafeteras Siemens"
          class="machine-brand-image"
        />
        <h2 class="machine-type-title">Cafeteras Siemens</h2>
      </article>
      <article class="machine-type">
        <img
          src="../assets/brand_bosch.webp"
          alt="Cafeteras Bosch"
          class="machine-brand-image"
        />
        <h2 class="machine-type-title">Cafeteras Bosch</h2>
      </article>
      <article class="machine-type">
        <img
          src="../assets/brand_ufesa.png"
          alt="Cafeteras Ufesa"
          class="machine-brand-image"
        />
        <h2 class="machine-type-title">Cafeteras Ufesa</h2>
      </article>
      <article class="machine-type">
        <img
          src="../assets/brand_taurus.png"
          alt="Cafeteras Taurus"
          class="machine-brand-image"
        />
        <h2 class="machine-type-title">Cafeteras Taurus</h2>
      </article>
      <article class="machine-type">
        <img
          src="../assets/brand_bonsenkitchen.png"
          alt="Cafeteras BonsenKitchen"
          class="machine-brand-image"
        />
        <h2 class="machine-type-title">Cafeteras BonsenKitchen</h2>
      </article>
      <article class="machine-type">
        <img
          src="../assets/brand_aigostar.png"
          alt="Cafeteras Aigostar"
          class="machine-brand-image"
        />
        <h2 class="machine-type-title">Cafeteras Aigostar</h2>
      </article>
      <article class="machine-type">
        <img
          src="../assets/brand_rusell_hobbs.png"
          alt="Cafeteras Russell Hobbs"
          class="machine-brand-image"
        />
        <h2 class="machine-type-title">Cafeteras Rusell Hobbs</h2>
      </article>
      <article class="machine-type">
        <img
          src="../assets/brand_tristar.png"
          alt="Cafeteras Tristar"
          class="machine-brand-image"
        />
        <h2 class="machine-type-title">Cafeteras Tristar</h2>
      </article>
      <article class="machine-type">
        <img
          src="../assets/brand_orbegozo.png"
          alt="Cafeteras Orbegozo"
          class="machine-brand-image"
        />
        <h2 class="machine-type-title">Cafeteras Orbegozo</h2>
      </article>
      <article class="machine-type">
        <img
          src="../assets/brand_monix.png"
          alt="Cafeteras Monix"
          class="machine-brand-image"
        />
        <h2 class="machine-type-title">Cafeteras Monix</h2>
      </article>
      <article class="machine-type">
        <img
          src="../assets/brand_godmorn.png"
          alt="Cafeteras Godmorn"
          class="machine-brand-image"
        />
        <h2 class="machine-type-title">Cafeteras Godmorn</h2>
      </article>
      <article class="machine-type">
        <img
          src="../assets/brand_bialetti.webp"
          alt="Cafeteras Bialetti"
          class="machine-brand-image"
        />
        <h2 class="machine-type-title">Cafeteras Bialetti</h2>
      </article>
      <article class="machine-type">
        <img
          src="../assets/brand_magfesa.png"
          alt="Cafeteras Magfesa"
          class="machine-brand-image"
        />
        <h2 class="machine-type-title">Cafeteras Magfesa</h2>
      </article>
      <article class="machine-type">
        <img
          src="../assets/brand_oroley.png"
          alt="Cafeteras Oroley"
          class="machine-brand-image"
        />
        <h2 class="machine-type-title">Cafeteras Oroley</h2>
      </article>
      <article class="machine-type">
        <img
          src="../assets/brand_bonvivo.webp"
          alt="Cafeteras bonVIVO"
          class="machine-brand-image"
        />
        <h2 class="machine-type-title">Cafeteras bonVIVO</h2>
      </article>
      <article class="machine-type">
        <img
          src="../assets/brand_bodum.png"
          alt="Cafeteras Bodum"
          class="machine-brand-image"
        />
        <h2 class="machine-type-title">Cafeteras Bodum</h2>
      </article>
      <article class="machine-type">
        <img
          src="../assets/brand_gronenberg.png"
          alt="Cafeteras Gronenberg"
          class="machine-brand-image"
        />
        <h2 class="machine-type-title">Cafeteras Gronenberg</h2>
      </article>
    </div>
    <h1>Tipos y modelos de cafeteras: Beneficios y Desventajas</h1>
    <p>
      Si aún no tienes claro que cafetera es la que más se ajusta a ti a
      continuación te indicamos nuestro criterio a la hora de seleccionar la
      mejor cafetera:
    </p>
    <img
      src="../assets/home_select_coffee_machine.webp"
      alt="Gente bebiendo diferentes tipos de café"
      class="types-and-models-image"
    />
    <ul>
      <li>
        <b>Potenciar las propiedades del cafe.</b> Cada tipo de cafetera procesa
        y produce el café de una manera única. Lo que implica que dependiendo de
        nuestros gustos deberíamos optar por un tipo de cafetea u otra. Si lo
        que te gusta es
        <i>mantener las propiedades y matices de los granos de café</i> quizá
        quieras optar por las cafeteras más tradicionales cómo las
        <b>cafeteras francesas</b> o <b>cafeteras italianas</b>. Si, en vez de
        los sabores originales de la tierra, andas en busca de un
        <i>café intenso</i> deberías optar por una <b>cafetera express</b> o
        <b>cafetera superautomática</b>.
      </li>
      <li>
        <b>Crema sí o crema no</b>. El mundo cafetero se encuentra divido entre
        los que les gusta un buen café con su espesa espuma (o crema) o los que
        por lo contrario desean degustar un <i>café limpio y líquido</i>. Si tu
        eres de estos últimos todas las cafeteras que infusionan como las
        <b>cafeteras francesas</b>. También las <b>cafeteras italianas</b> o las
        <b>cafeteras de goteo</b> produciran este mismo café. Por otro lado si
        tu <i>disfrutas tanto del café como de su crema</i> dispones de una
        amplia gama de cafeteras que gracias a la presión con la que el agua
        filtra el café producen esta deliciosa crema. Las
        <b>cafeteras express</b> o las <b>cafeteras superautomáticas</b> así
        como las de capsulas son un buen ejemplo de ello.
      </li>
      <li>
        <b>Comodidad</b>. El tiempo es oro y no siempre tenemos las ganas de
        invertir mucho tiempo en la preparación de nuestro bebida oscura
        favorita. Pero eso hoy en día no nos impide disfurtar de una estupenda
        taza de café. <i>Sí no disponemos de mucho tiempo</i> o no queremos
        complicarnos con la elaboración lo mejor es optar por las
        <b>cafeteras superautomáticas</b> o las <b>cafeteras de cápsulas</b>. Si
        por el contrario <i>dispones de tiempo</i> y te apasiona la elaboración
        deberías optar por las cafeteras más tradicionales, como las
        <b>cafeteras italianas</b> o las <b>cafeteras francesas</b>.
      </li>
      <li>
        <b>Precio</b>. No nos engañemos, un factor clave para seleccionar la
        cafetera será el dinero que estemos dispuestos a invertir en nuestra
        máquina cafetera. La buena noticia es que las hay de todos los precios.
        Desde muy <i>baratas</i> como las
        <b>cafeteras francesas o italianas</b> a cafeteras más <i>caras</i> como
        las <b>cafeteras express o superautomáticas</b>.
      </li>
    </ul>
    <p>
      Te encuentras en
      <b>el sitio perfecto para encontrar esa cafetera perfecta</b>
      que te proporcionará a ti y a los tuyos unas mañanas redondas.
    </p>
  </Frame>
</template>

<script>
import Frame from "@/components/Frame.vue";
import Cluster from "@/components/Cluster.vue";
import AffiliateGrid from "@/components/AffiliateGrid.vue";

export default {
  name: "Home",
  components: {
    Frame,
    Cluster,
    AffiliateGrid,
  },
};
</script>

<style scoped>
p,
li {
  line-height: 150%;
  font-size: large;
}
li {
  margin-bottom: 8px;
}
.machine-types {
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
}
.machine-type {
  flex: 1;
  min-width: 100%;
}
@media (min-width: 768px) {
  .machine-type {
    min-width: 30%;
  }
}
.machine-type-image {
  object-fit: cover;
  width: 100%;
  height: 156px;
}
.machine-brand-image {
  object-fit: contain;
  width: 100%;
  height: 156px;
}
.machine-type-title {
  margin-top: 8px;
}
.types-and-models-image {
  object-fit: cover;
  width: 100%;
  height: 512px;
}
</style>
